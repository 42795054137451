import React from 'react'

import { Source, Ticker } from 'types'
import { Model } from 'types/models'
import AssetClass from 'types/assetClass'

import useMultiSeriesChart from 'hooks/useMultiSeriesChart'
import MultiLineSeriesWrapper from '../common/series/MultiSeriesWrapper'
import { SeriesPills } from '../common/series/Selector'
import ChartLoader from '../ChartLoader'
import useCatalog from 'hooks/useCatalog'

const OptionsPutCallSkew: React.FC<{ availableTenors?: `${number}d`[] }> = ({
  availableTenors,
}) => {
  const title = 'Put/call skew'

  const { data: catalog, isLoading: catalogLoading } = useCatalog({
    assets: [AssetClass.OPTION],
    active: [true],
    sources: [Source.DERIBIT],
    generateCurrencyPairs: true,
  })

  const expiries = Array.from(
    new Set(catalog?.deribit?.OPTION?.BTC?.active?.map((i) => i.expiry) || []),
  ).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

  const { chartSeries, highlighted, setHighlighted } = useMultiSeriesChart(
    title,
    [
      {
        assetClass: AssetClass.OPTION,
        middle: {
          model: Model.SVI,
          currency: Ticker.BTC,
          tenor: availableTenors?.[0] ?? '7d',
          series: '45delta',
        },
        suffix: 'skew',
        source: Source.DERIBIT,
      },
      {
        assetClass: AssetClass.OPTION,
        middle: {
          model: Model.SVI,
          currency: Ticker.BTC,
          tenor: availableTenors?.[0] ?? '7d',
          series: '45delta',
        },
        suffix: 'skew',
        source: Source.BLOCKSCHOLES,
      },
      {
        isListedExpiry: true,
        expiry: `${expiries?.[4]?.slice(0, 19)}Z`,
        source: Source.DERIBIT,
        baseAsset: Ticker.BTC,
        model: Model.SVI,
        suffix: 'skew',
        field: '25delta',
      },
      {
        isListedExpiry: true,
        expiry: `${expiries?.[4]?.slice(0, 19)}Z`,
        source: Source.BLOCKSCHOLES,
        baseAsset: Ticker.BTC,
        model: Model.SVI,
        suffix: 'skew',
        field: '25delta',
      },
    ],
  )

  return catalogLoading ? (
    <ChartLoader />
  ) : (
    <MultiLineSeriesWrapper
      chartSeries={chartSeries}
      title={title}
      id={title}
      axisLabel="%"
      axis={{ LEFT: 'p2' }}
      assetType={AssetClass.OPTION}
      series={[
        '5delta',
        '10delta',
        '20delta',
        '25delta',
        '30delta',
        '35delta',
        '40delta',
        '45delta',
      ]}
      useTypeToggle
      suffix="skew"
      useTenors
      availableTenors={availableTenors}
      listedExpiry={{ enabled: true }}
      fetchListedExpiries
      seriesPills={
        <SeriesPills
          chartSeries={chartSeries}
          id={title}
          setHighlighted={setHighlighted}
        />
      }
      highlightedIndx={highlighted}
    />
  )
}

export default OptionsPutCallSkew
