import { parse } from 'date-fns'
import { DataFrequency, getActiveOptionListedExpiry } from 'services'
import { ChartState } from 'stores/types'
import { DerivedOptionQfn, TimeSeriesData } from 'types'
import { SABRModelMap, SVIModelMap } from 'types/models'
import { getChartTimeStampRange } from 'utils/charts'
import useTimeSeriesQuery from './useTimeSeriesQuery'

const useDerivedActiveListedExpiry = ({
  queryKeys,
  enabled,
  timestampRange,
  dataFreq,
  refetchInterval,
  refetchOnWindowFocus,
}: {
  queryKeys: DerivedOptionQfn[]
  enabled: boolean | undefined
  timestampRange: ChartState['timings']
  dataFreq: DataFrequency
  refetchInterval: number
  refetchOnWindowFocus: boolean
}) => {
  const queries = Array.from(new Set(queryKeys)).map((q) =>
    getActiveOptionListedExpiry({
      key: q,
      refetchInterval,
      refetchOnWindowFocus,
    }),
  )

  const { data, isLoading, errors } = useTimeSeriesQuery<
    Record<string, Record<string, number[]>> & { key: string }
  >({
    queries,
    timestamp: getChartTimeStampRange(timestampRange, dataFreq),
    frequency: dataFreq,
    enabled,
  })

  const activeListedData = data?.reduce((acc, d) => {
    const entries = Object.entries(d)
    const split = d.key.split(`.${dataFreq}`)

    entries.forEach(([k, v]) => {
      acc[`${split[0]}.${k}.${dataFreq}${split[1]}`] = v
    })
    return acc
  }, {})

  const getActiveTimeSeries = (key: string, field: string): TimeSeriesData => {
    if (!key || !activeListedData?.[key]) {
      return {
        key: `${key}.${field}`,
        dataPoints: [],
      }
    }
    const storedField = key.includes('SABR')
      ? SABRModelMap[field]
      : SVIModelMap[field]
    const splitKey = key.split('.')
    const arr =
      activeListedData[key]?.[field] ||
      activeListedData[key]?.[storedField] ||
      activeListedData[key]?.[splitKey[splitKey.length - 1]]

    return {
      key: `${key}.${field}`,
      dataPoints: arr?.map((f, indx) => ({
        y: f,
        x: activeListedData[key].timestamp[indx],
      })),
    }
  }
  const listedExpiries =
    data && data[0]
      ? Object.keys(data[0])
          .filter((k) => k !== 'key')
          .sort((a, b) => new Date().getTime() - new Date().getTime())
      : []

  return {
    listedExpiries,
    activeListedData,
    isLoading,
    getActiveTimeSeries,
    errors,
  }
}

export default useDerivedActiveListedExpiry
